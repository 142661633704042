export const validate = formFields => values => {
  return formFields.reduce((obj, field) => {
    const rules = field.validationRules
    const fieldName = field.name
    const fieldValue = values[fieldName]
    if (rules) {
      // We use .find() because we only want to display the first
      // error we find. If we wanted to display all validation errors
      // for each field, we'd use .map() and string.join().
      rules.find(rule => {
        const error = rule(fieldValue)

        if (!obj[fieldName]) {
          obj[fieldName] = error
          return true
        }

        return false
      })
    }
    return obj
  }, {})
}

const requiredWithMsg = msg => value => (!value ? msg : undefined)
const required = requiredWithMsg('Required')
const minLength = length => value =>
  !value || value.length < length
    ? `Must be at least ${length} chars`
    : undefined
const maxLength = length => value =>
  !value || value.length > length
    ? `Must be no more than ${length} chars`
    : undefined
const regex = regex => value =>
  !value || !regex.test(value) ? 'Invalid form' : undefined

export const Validators = {
  required,
  requiredWithMsg,
  minLength,
  maxLength,
  regex,
}
