// this comment tells babel to convert jsx to
// calls to a function called jsx
// instead of React.createElement
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { Field } from 'redux-form'
import { Select } from 'antd'

const { Option } = Select

const AntSelect = ({
  formField: { name, placeholder },
  options,
  // change is a redux-form prop
  // https://redux-form.com/8.2.2/docs/api/props.md/#-code-change-field-string-value-any-function-code-
  change,
  // input is a redux-form prop
  // https://redux-form.com/8.2.2/docs/api/field.md/#input-props
  input,
  // meta is a redux-form prop
  // https://redux-form.com/8.2.2/docs/api/field.md/#meta-props
  meta: { touched, error },
  ...rest
}) => {
  const getStyles = ({ touched, error }) => {
    const errorColor = '#f44336'

    return css`
      font-size: 1.4rem;
      .ant-select-selection__placeholder,
      .ant-select-search__field__placeholder {
        color: ${touched && error ? errorColor : '#757575'};
      }
      .ant-select-selection--single {
        height: 45px;
        border-color: ${touched && error ? errorColor : '#534dc9'} !important;
        .ant-select-selection__rendered {
          .ant-select-selection-selected-value {
            display: flex !important;
            align-items: center !important;
          }
        }
        div {
          height: inherit !important;
        }
        .ant-select-arrow {
          svg {
            fill: ${touched && error ? errorColor : '#534dc9'} !important;
          }
        }
      }
    `
  }

  // TODO display error based on meta.touched, meta.invalid, and meta.error
  return (
    <div>
      <Select
        css={getStyles({ touched, error })}
        size="large"
        showSearch
        dropDownOffset={{ x: 0, y: -10 }}
        placeholder={placeholder}
        optionFilterProp="children"
        onChange={value => {
          //console.log(`changed ${name} to ${value}`)
          change(name, value)
        }}
        // onFocus={onFocus}
        // onSearch={onSearch}
        filterOption={(input, option) =>
          option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
        }
        {...rest}
      >
        {options.map(({ value, text }) => (
          <Option key={value} value={value}>
            {text}
          </Option>
        ))}
      </Select>
      {touched && error ? (
        <span
          style={{
            color: '#f44336',
            margin: '8px 14px 0',
            fontSize: '1.2rem',
          }}
        >
          {error}
        </span>
      ) : (
        ''
      )}
    </div>
  )
}

const IrisSelect = ({ formField, options, ...rest }) => {
  return (
    <Field
      name={formField.name}
      formField={formField}
      options={options}
      // autoComplete="new-password"
      // autoCorrect="off"
      // spellCheck="off"
      component={AntSelect}
      {...rest}
    />
  )
}

export default IrisSelect
