import React from 'react'
import TextField from '@material-ui/core/TextField'
import { Field } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'

const purpleColor = '#534DC9'

const MyTextField = withStyles({
  root: {
    '& div': {
      '& input': {
        height: 25,
        padding: 10,
      },
    },
    '& label.Mui-focused': {
      // label text color in focused state
      color: purpleColor,
    },
    '& label': {
      top: -3,
      left: -3
    },
    '& .MuiOutlinedInput-root': {
      '& fieldset': {
        // border color in default state
        borderColor: purpleColor,
      },      
      '&:hover fieldset': {
        // border color in hovered state
        borderColor: purpleColor,
        // increase border thickness to 2 pixels when hovered over
        borderWidth: '0.2rem',
      },
      '&.Mui-focused fieldset': {
        // border color in focused state
        borderColor: purpleColor,
      },
    },
  },
})(props => <TextField {...props} />)

const renderTextField = ({
  label,
  input,
  meta: { touched, invalid, error },
  ...rest
}) => {
  return (
    <MyTextField
      label={label}
      placeholder={label}
      error={touched && invalid}
      helperText={touched && error}
      inputProps={{
        style: {
          fontSize: '1.4rem',
        },
      }}
      InputLabelProps={{
        style: {
          fontSize: '1.4rem',
        },
      }}
      {...input}
      {...rest}
    />
  )
}

const IrisTextField = ({ formField, ...rest }) => {
  return (
    <Field
      name={formField.name}
      label={formField.placeholder}
      placeholder={formField.placeholder}
      type="text"
      component={renderTextField}
      variant="outlined"
      {...rest}
    />
  )
}

export default IrisTextField
