import 'react-app-polyfill/ie9'
import 'react-app-polyfill/stable'

// this comment tells babel to convert jsx to
// calls to a function called jsx
// instead of React.createElement
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx, css, Global } from '@emotion/core'

// eslint-disable-next-line no-unused-vars
import React from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'

import 'antd/dist/antd.css'
import './index.css'

import Signup from './signup'
import ConfirmAccount from './confirm'
import * as serviceWorker from './serviceWorker'
import { Switch, Route, Redirect } from 'react-router-dom'
import { ConnectedRouter as Router } from 'connected-react-router'
import { getHistory } from './history'
import { getStore } from './configureStore'
import { createMuiTheme, ThemeProvider } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import CookieConsent from './common/CookieConsent'
import CookiesPolicy from './cookies-policy'

const history = getHistory()
const store = getStore()

const globalFontStyles = css`
  @font-face {
    font-family: 'irisvr';
    font-display: fallback;
    src: url('/fonts/Px-Grotesk-Regular.woff2') format('woff2');
  }

  @font-face {
    font-family: 'irisvr-bold';
    font-display: fallback;
    src: url('/fonts/Px-Grotesk-Bold.woff2') format('woff2');
  }

  @font-face {
    font-family: 'irisvr-light';
    font-display: fallback;
    src: url('/fonts/Px-Grotesk-Light.woff2') format('woff2');
  }
`

const App = () => {
  return (
    <>
      <Global styles={globalFontStyles} />
      <Router history={history}>
        <Switch>
          <Route path="/cookies-policy" component={CookiesPolicy} />
          <Route path="/confirm" component={ConfirmAccount} />
          <Route path="/step/:stepNumber" component={Signup} />
          <Route path="/">
            <Redirect
              to={{
                pathname: `/step/1`,
                search: window.location.search,
              }}
            />
          </Route>
        </Switch>
      </Router>
    </>
  )
}

const theme = createMuiTheme({
  typography: {
    // Tell Material-UI what's the font-size on the html element is.
    htmlFontSize: 10,
    fontFamily: [
      'irisvr',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
  },
})

ReactDOM.render(
  <Provider store={store}>
    <ThemeProvider theme={theme}>
      <Typography>
        <App />
        <CookieConsent />
      </Typography>
    </ThemeProvider>
  </Provider>,
  document.getElementById('root')
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
