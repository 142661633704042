const prefix = 'IrisVR@@'
export const Actions = {
  advanceStepRequest: prefix + 'signup/advanceStepRequest',
  advanceStepSuccess: prefix + 'signup/advanceStepSuccess',
  advanceStepFailure: prefix + 'signup/advanceStepFailure',
  setRecaptcha: prefix + 'signup/setRecaptcha',
  storeToken: prefix + 'signup/storeToken',
  storeAccountID: prefix + 'signup/storeAccountID',
  resendAccountConfirmationEmail:
    prefix + 'signup/resendAccountConfirmationEmail',
}

export const ActionCreators = {
  advanceStepRequest: () => ({ type: Actions.advanceStepRequest }),
  advanceStepSuccess: () => ({ type: Actions.advanceStepSuccess }),
  advanceStepFailure: payload => ({
    type: Actions.advanceStepFailure,
    payload,
  }),
  setRecaptcha: () => ({
    type: Actions.setRecaptcha,
  }),
  storeToken: payload => ({
    type: Actions.storeToken,
    payload,
  }),
  storeAccountID: payload => ({
    type: Actions.storeAccountID,
    payload,
  }),
  resendAccountConfirmationEmail: () => ({
    type: Actions.resendAccountConfirmationEmail,
  }),
}

export const reducerKey = 'signup'

export const Selectors = {
  stepNumber: state => state[reducerKey].stepNumber,
  token: state => state[reducerKey].token,
  accountID: state => state[reducerKey].accountID,
  recaptcha: state => state[reducerKey].recaptcha,
}

function reducer(state = { stepNumber: 1 }, action) {
  switch (action.type) {
    case Actions.advanceStepSuccess:
      return {
        ...state,
        stepNumber: state.stepNumber + 1,
      }
    case Actions.storeToken:
      return {
        ...state,
        token: action.payload,
      }
    case Actions.storeAccountID:
      return {
        ...state,
        accountID: action.payload,
      }
    case Actions.setRecaptcha:
      return {
        ...state,
        recaptcha: true,
      }
    default:
      return state
  }
}

export default reducer
