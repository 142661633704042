import React from 'react'
import { Button } from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'

const IrisButton = withStyles({
  root: {
    boxShadow: 'none',
    height: '5rem',
    fontWeight: '600',
    backgroundColor: '#534dc8',
  },
})(({ children, ...rest }) => <Button {...rest}>{children}</Button>)

export default IrisButton
