// this comment tells babel to convert jsx to
// calls to a function called jsx
// instead of React.createElement
/** @jsx jsx */
import { jsx } from '@emotion/core'
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import Step1 from './Step1'
import Step2 from './Step2'
import Step3 from './Step3'
import Testimonial from './Testimonial'
import { ActionCreators, Selectors } from './duck'
import { useParams, useLocation } from 'react-router-dom'
import queryString from 'query-string'

import background1 from './images/illustration-1.png'
import background2 from './images/illustration-2.png'

const Signup = ({ actions: { resendAccountConfirmationEmail } }) => {
  let { stepNumber } = useParams()
  const location = useLocation()
  const parsed = queryString.parse(location.search)
  const { email } = parsed
  const browserWidth = window.innerWidth

  let step
  switch (stepNumber) {
    case '1':
      step = <Step1 email={email} />
      break
    case '2':
      step = <Step2 browserWidth={browserWidth} />
      break
    case '3':
      step = (
        <Step3
          resendAccountConfirmationEmail={resendAccountConfirmationEmail}
        />
      )
      break
    default:
      throw new Error(`Unknown stepNumber: ${stepNumber}`)
  }

  const getBackground = stepNumber => {
    switch (stepNumber) {
      default:
      case '1':
        return `url(${background1})`
      case '2':
      case '3':
        return `url(${background2})`
    }
  }

  const signupStyles = ({ stepNumber }) => {
    return {
      backgroundColor: '#F6F8F8',
      minHeight: '100vh',
    }
  }

  const backgroundImageWidth = 67
  const backgroundImageHeight = Math.floor((backgroundImageWidth * 2) / 3)

  const topStyles = {
    '@media (min-width: 921px)': {
      paddingTop: '8rem',
      paddingLeft: '10rem',
      paddingBottom: '12rem',
      maxWidth: 1180,
      minHeight: 500,
      margin: 'auto',
      backgroundImage: getBackground(stepNumber),
      backgroundRepeat: 'no-repeat',
      backgroundPosition: 'right center',
      backgroundSize: `${backgroundImageWidth}rem ${backgroundImageHeight}rem`,
    },
    '@media (max-width: 920px)': {
      padding: '2rem 0 0 0',
      margin: 'auto',
      maxWidth: 400,
      width: '95%',
    },
  }

  return (
    <div css={signupStyles}>
      <div css={topStyles}>{step}</div>
      <Testimonial />
    </div>
  )
}

const mapStateToProps = createStructuredSelector({
  stepNumber: Selectors.stepNumber,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      resendAccountConfirmationEmail:
        ActionCreators.resendAccountConfirmationEmail,
    },
    dispatch
  ),
})

export default connect(mapStateToProps, mapDispatchToProps)(Signup)
