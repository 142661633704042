import React from 'react'
import Checkbox from '@material-ui/core/Checkbox'
import FormControl from '@material-ui/core/FormControl'
import FormControlLabel from '@material-ui/core/FormControlLabel'
import FormHelperText from '@material-ui/core/FormHelperText'
import { Field } from 'redux-form'
import { withStyles } from '@material-ui/core/styles'

const purpleColor = '#534DC9'

const checkboxDimensions = '23px'

const StyledCheckbox = withStyles({
  root: {
    color: purpleColor,
    padding: '0 0.9rem',
    '&$checked': {
      color: purpleColor,
    },
    '&&:hover': {
      backgroundColor: 'transparent',
    },
    '& span': {
      '& svg': {
        width: checkboxDimensions,
        height: checkboxDimensions,
      },
    },
  },
  checked: {},
})(props => <Checkbox color="default" {...props} />)

const IrisFormControlLabel = withStyles({
  root: {
    '& span': {
      fontSize: '12px',
    },
  },
})(props => <FormControlLabel {...props} />)

const renderCheckbox = ({
  input,
  label,
  meta: { touched, invalid, error },
}) => (
  <FormControl required error={error} component="fieldset">
    <IrisFormControlLabel
      control={
        <StyledCheckbox checked={!!input.value} onChange={input.onChange} />
      }
      label={label}
    />
    {touched && invalid && error && <FormHelperText>{error}</FormHelperText>}
  </FormControl>
)

const IrisCheckbox = ({ formField, ...rest }) => {
  return (
    <Field
      name={formField.name}
      label={formField.placeholder}
      component={renderCheckbox}
      {...rest}
    />
  )
}

export default IrisCheckbox
