// this comment tells babel to convert jsx to
// calls to a function called jsx
// instead of React.createElement
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
// eslint-disable-next-line no-unused-vars
import React from 'react'

import thorntonLogo from './thornton.svg'
import perkinsLogo from './perkins.svg'
import blackVeatchLogo from './black-veatch.svg'
// import hmcLogo from './hmc.svg'
import mortensonLogo from './mortenson.svg'
import gilbaneLogo from './gilbane.svg'

const Testimonial = ({ additionalCSS }) => {
  const baseContainerClass = css`
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2rem;
    background-color: white;
    padding-bottom: 20rem;
    @media (max-width: 920px) {
      display: none;
    }
  `
  const containerClass = additionalCSS
    ? [baseContainerClass, additionalCSS]
    : baseContainerClass

  const logos = [
    {
      src: thorntonLogo,
      name: 'Thornton Tomasetti',
    },
    {
      src: perkinsLogo,
      name: 'Perkins + Will',
    },
    {
      src: blackVeatchLogo,
      name: 'Black & Veatch',
    },
    {
      src: mortensonLogo,
      name: 'Mortenson',
    },
    {
      src: gilbaneLogo,
      name: 'Gilbane',
    },
  ]

  const imageDivStyles = css`
    display: flex;
    justify-content: center;
  `

  const titleStyles = css`
    font-size: 3.4rem;
    margin-top: 8rem;
  `

  const subtitleStyles = css`
    font-size: 2.1rem;
    margin-top: 2rem;
    font-weight: 400;
    color: #9d9d9d;
    letter-spacing: 0.1rem;
  `

  const imageGridStyles = css`
    margin-top: 6rem;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    grid-template-rows: repeat(2, 1fr);
    grid-gap: 8rem;
  `
  return (
    <div css={containerClass}>
      <div css={titleStyles}>
        The most influential AEC firms use Prospect by IrisVR.
      </div>
      <div css={subtitleStyles}>Join 60,000 users from over 100 countries</div>
      <div css={imageGridStyles}>
        {logos.map((l, i) => (
          <div key={i} css={imageDivStyles}>
            <img
              src={l.src}
              alt={`${l.name} logo`}
              style={{ display: 'block', width: '100%' }}
            />
          </div>
        ))}
      </div>
    </div>
  )
}

export default Testimonial
