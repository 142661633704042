// const getBaseURL = 'http://api.irisvr-dev.com'

const getBaseURL =
  process.env.NODE_ENV === 'development'
    ? 'http://localhost:3000'
    : `${window.location.protocol}//api.${window.location.host.substring(
        window.location.host.indexOf('irisvr')
      )}`

const getUsersAPIURL = () => `${getBaseURL}/v3/users`

const createUser = async body => {
  const url = getUsersAPIURL()
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
    },
    body: JSON.stringify(body),
  }

  const response = await fetch(url, options)
  return parseServerResponse(response)
}

const patchUser = async ({ token, userID, body }) => {
  const url = `${getUsersAPIURL()}/${userID}`
  const options = {
    method: 'PATCH',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
  }

  const response = await fetch(url, options)
  return parseServerResponse(response)
}

const resendAccountConfirmationEmail = async ({ token }) => {
  const url = `${getUsersAPIURL()}/email-confirmation`
  const options = {
    method: 'POST',
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json;charset=UTF-8',
      Authorization: `Bearer ${token}`,
    },
  }

  const response = await fetch(url, options)
  return parseServerResponse(response)
}

const parseServerResponse = async response => {
  if (response.ok) {
    const json = await response.json()

    if (json.meta) {
      if (json.meta.error_message) {
        // If there's an error message, just display it to the user
        throw new Error(trimErrorMessage(json.meta.error_message))
      } else if (json.meta.code === 0) {
        // If there's no error code, then everything worked!
        return json.data
      } else {
        // If there's no meta.error_message and meta.code is non-zero, then bomb.
        // This should never happen!
        throw new Error(`Non-0 meta.code with no json.meta.error_message`)
      }
    } else {
      throw new Error(`200 response with no meta field`)
    }
  } else {
    throw new Error(`Non 200 status code: ${response.status}`)
  }
}

export const trimErrorMessage = msg => {
  if (msg.startsWith('Error: ')) {
    msg = msg.substring('Error: '.length)
  }
  return msg.trim()
}

export const Api = {
  createUser,
  patchUser,
  resendAccountConfirmationEmail,
}
