// this comment tells babel to convert jsx to
// calls to a function called jsx
// instead of React.createElement
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { IrisButton } from '../common'
import { makeStyles } from '@material-ui/core/styles'

const textCentered = css`
  text-align: center;
`

const horizontallyCentered = css`
  display: flex;
  justify-content: center;
  align-items: center;
`

const thankYouStyles = [
  textCentered,
  horizontallyCentered,
  css`
    font-size: 1.2rem;
    font-weight: 600;
    margin-top: 6rem;
    margin-bottom: 4rem;
  `,
]

const containerStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
`

const cardStyles = css`
  display: flex;
  flex-direction: column;
  align-items: center;

  width: 40rem;
  padding: 4rem 0;

  border-style: solid;
  border-color: rgb(220, 220, 220);
  border-width: 0.1rem;
  border-radius: 0.1rem;
  border-top: 0.4rem solid rgb(55, 71, 172);
`

const downloadStyles = [
  textCentered,
  horizontallyCentered,
  css`
    max-width: 25rem;
    font-size: 2rem;
    font-weight: 700;
    margin-bottom: 2rem;
  `,
]

const orStyles = [
  textCentered,
  horizontallyCentered,
  css`
    font-weight: 600;
    margin-top: 3rem;
    margin-bottom: 3rem;
  `,
]

const loginStyles = [
  textCentered,
  horizontallyCentered,
  css`
    font-weight: 600;
    margin-bottom: 2rem;
  `,
]

const useStyles = makeStyles({
  root: {
    // padding: '1rem',
  },
  label: {
    textTransform: 'uppercase',
    fontWeight: 600,
  },
})

const ConfirmAccount = () => {
  const classes = useStyles()

  return (
    <div css={containerStyles}>
      <div css={thankYouStyles}>Thank you for confirming your email</div>
      <div css={cardStyles}>
        <div css={downloadStyles}>
          Download Prospect to start your free trial
        </div>
        <div css={horizontallyCentered}>
          <IrisButton
            variant="contained"
            color="primary"
            classes={{
              root: classes.root, // class name, e.g. `classes-nesting-root-x`
              label: classes.label, // class name, e.g. `classes-nesting-label-x`
            }}
          >
            Download Prospect
          </IrisButton>
        </div>
        <div css={orStyles}>OR</div>
        <div css={loginStyles}>Log in to manage your account settings</div>
        <div css={horizontallyCentered}>
          <IrisButton
            variant="outlined"
            color="primary"
            classes={{
              root: classes.root, // class name, e.g. `classes-nesting-root-x`
              label: classes.label, // class name, e.g. `classes-nesting-label-x`
            }}
          >
            Log in
          </IrisButton>
        </div>
      </div>
    </div>
  )
}

export default ConfirmAccount
