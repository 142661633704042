import { call, put, select, takeEvery, fork } from 'redux-saga/effects'
import { push } from 'connected-react-router'
import { formValueSelector } from 'redux-form'
import {
  FormFieldNames as step1FormFields,
  formName as step1FormName,
} from './Step1'
import {
  FormFieldNames as step2FormFields,
  formName as step2FormName,
} from './Step2'
import { Api } from '../api'
import { ActionCreators, Actions, Selectors } from './duck'
import { getHistory } from '../history'
import { actionTypes as reduxFormActionTypes, change } from 'redux-form'
import queryString from 'query-string'
import { isoCodeToName } from '../common/geo'

function* listenForSignupSaga() {
  yield takeEvery(Actions.advanceStepRequest, signupSaga)
}

function* signupSaga() {
  const stepNumber = yield select(Selectors.stepNumber)

  let success = false
  if (stepNumber === 1) {
    success = yield call(signupStep1)
  } else if (stepNumber === 2) {
    success = yield call(signupStep2)
  }

  if (success) {
    // The order matters here
    yield put(ActionCreators.advanceStepSuccess())
    const stepNumber = yield select(Selectors.stepNumber)
    yield put(push(`/step/${stepNumber}`))
  }
}

const personalInfoKey = 'personal_info'

function* signupStep1() {
  const step1FormSelector = formValueSelector(step1FormName)

  const recaptcha = yield select(state => Selectors.recaptcha(state))
  if (!recaptcha) {
    yield put(ActionCreators.advanceStepFailure(new Error('Must pass Captcha')))
  }

  const fullName = yield select(state =>
    step1FormSelector(state, step1FormFields.fullName.name)
  )

  const body = {
    [step1FormFields.username.name]: yield select(state =>
      step1FormSelector(state, step1FormFields.username.name)
    ),
    [step1FormFields.password.name]: yield select(state =>
      step1FormSelector(state, step1FormFields.password.name)
    ),
    [step1FormFields.fullName.name]: fullName,
    display_name: fullName,
    [step1FormFields.emailSubscription.name]: {
      general: {
        subscribed: yield select(state =>
          step1FormSelector(state, step1FormFields.emailSubscription.name)
        ),
      },
    },
    [personalInfoKey]: {
      [step1FormFields.company.name]: yield select(state =>
        step1FormSelector(state, step1FormFields.company.name)
      ),
    },
  }

  try {
    const { token, _id: accountID } = yield call(Api.createUser, body)
    yield put(ActionCreators.storeToken(token))
    yield put(ActionCreators.storeAccountID(accountID))

    const analytics = window.analytics
    if (analytics) {
      analytics.track('signup_account_created', {
        userID: accountID,
      })
    }
  } catch (e) {
    yield put(ActionCreators.advanceStepFailure(e))
    return false
  }

  return true
}

function* signupStep2() {
  const step2FormSelector = formValueSelector(step2FormName)

  // The value that gets stored in the country field is
  // actually the country's ISO code, so we have to convert it
  // to its full name when we send it to the server.
  // TODO in the long run the server should use ISO codes.
  const countryIsoCode = yield select(state =>
    step2FormSelector(state, step2FormFields.country.name)
  )
  const country = isoCodeToName(countryIsoCode)

  const region = yield select(state =>
    step2FormSelector(state, step2FormFields.region.name)
  )
  const phoneNumber = yield select(state =>
    step2FormSelector(state, step2FormFields.phoneNumber.name)
  )
  const jobTitle = yield select(state =>
    step2FormSelector(state, step2FormFields.jobTitle.name)
  )
  const industry = yield select(state =>
    step2FormSelector(state, step2FormFields.industry.name)
  )
  const city = yield select(state =>
    step2FormSelector(state, step2FormFields.city.name)
  )

  const body = {
    fields_changed: [
      step2FormFields.country.name,
      step2FormFields.region.name,
      step2FormFields.phoneNumber.name,
      step2FormFields.jobTitle.name,
      step2FormFields.industry.name,
      step2FormFields.city.name,
    ],
    [step2FormFields.country.name]: country,
    [step2FormFields.region.name]: region,
    [step2FormFields.phoneNumber.name]: phoneNumber,
    [step2FormFields.jobTitle.name]: jobTitle,
    [step2FormFields.industry.name]: industry,
    [step2FormFields.city.name]: city,
  }

  const token = yield select(Selectors.token)
  const userID = yield select(Selectors.accountID)

  try {
    yield call(Api.patchUser, { token, userID, body })
  } catch (e) {
    yield put(ActionCreators.advanceStepFailure(e))
    return false
  }

  const analytics = window.analytics
  if (analytics) {
    analytics.track('signup_profile_enhanced', {
      userID,
      country,
      region,
      phoneNumber,
      jobTitle,
      industry,
      city,
    })
  }

  return true
}

function* listenForResendAccountConfirmationEmail() {
  yield takeEvery(
    Actions.resendAccountConfirmationEmail,
    resendAccountConfirmationEmail
  )
}

function* resendAccountConfirmationEmail() {
  const token = yield select(Selectors.token)
  try {
    yield call(Api.resendAccountConfirmationEmail, { token })
  } catch (e) {
    yield put(ActionCreators.advanceStepFailure(e))
  }
}

function* listenForReduxFormInit() {
  yield takeEvery(reduxFormActionTypes.INITIALIZE, updateEmailField)
}

function* updateEmailField(action) {
  if (action.meta.form === step1FormName) {
    const history = getHistory()
    const parsed = queryString.parse(history.location.search)
    const email = parsed.email
    if (email) {
      const analytics = window.analytics
      if (analytics) {
        analytics.track('signup_email_submitted', {
          email,
        })
      }

      yield put(change(step1FormName, step1FormFields.username.name, email))
    }
  }
}

export const sagas = [
  fork(listenForSignupSaga),
  fork(listenForResendAccountConfirmationEmail),
  fork(listenForReduxFormInit),
]
