// this comment tells babel to convert jsx to
// calls to a function called jsx
// instead of React.createElement
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx, css } from '@emotion/core'
// eslint-disable-next-line no-unused-vars
import React from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { reduxForm, formValueSelector, SubmissionError } from 'redux-form'
import SignupCard from '../SignupCard'
import {
  IrisTextField,
  IrisSelect,
  IrisButton,
  validate,
  Validators,
} from '../../common'
import { regions, countries } from '../../common/geo'
import MakeAsyncFunction from 'react-redux-promise-listener'
import { promiseListener } from '../../configureStore'
import { Actions } from '../duck'

const stepNumber = 2

const formStyles = css`
  @media (min-width: 921px) {
    display: grid;
    grid-template-columns: 210px 210px;
    grid-column-gap: 10px;
    grid-gap: 1.5rem;
  }
`
const getFormStyles = () => formStyles

const doubleColumn = css`
@media (min-width: 921px){
  grid-column: 1/3;
}
@media (max-width: 920px) {
  width: 100%;
`

const fullWidth = css`
  @media (min-width: 921px) {
    width: 100%;
  }
  @media (max-width: 920px) {
    width: 100%;
    margin-bottom: 10px;
  }
`
const textFieldStyles = [fullWidth]

const countryOptions = countries.map(country => ({
  value: country.alpha2,
  text: country.name,
}))

const industryList = [
  'Architecture & Design',
  'BIM/VDC Consultant',
  'Owner',
  'Construction',
  'Education',
  'Energy, Oil & Gas',
  'Engineering',
  'Manufacturing',
  'Other',
]

const industryOptions = industryList.map(i => ({
  value: i,
  text: i,
}))

export const isPhoneNumber = value => {
  if (value) {
    value = value.replace(/\D/g, '')
    return value.length < 7 ? 'Invalid, must be at least 7 digits' : undefined
  }
  return 'invalid #'
}

export const FormFieldNames = {
  country: {
    name: 'country',
    placeholder: 'Country',
    defaultValue: '',
    validationRules: [Validators.required],
  },
  region: {
    name: 'state',
    placeholder: 'State/Region',
    defaultValue: '',
    // TODO region isn't required yet b/c some countries (e.g., Anguilla) don't have regions
  },
  phoneNumber: {
    name: 'phone',
    placeholder: 'Phone Number',
    defaultValue: '',
    validationRules: [Validators.required, isPhoneNumber],
  },
  jobTitle: {
    name: 'job_title',
    placeholder: 'Job Title',
    defaultValue: '',
    validationRules: [Validators.required, Validators.minLength(2)],
  },
  industry: {
    name: 'industry',
    placeholder: 'Industry',
    defaultValue: '',
    validationRules: [Validators.required, Validators.minLength(2)],
  },
  city: {
    name: 'city',
    placeholder: 'City',
    defaultValue: '',
    validationRules: [Validators.required, Validators.minLength(2)],
  },
}

const Step2 = ({
  change,
  countryIsoCode,
  error,
  handleSubmit,
  browserWidth,
}) => {
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={Actions.advanceStepRequest} // the type of action to dispatch when this function is called
      resolve={Actions.advanceStepSuccess} // the type of action that will resolve the promise
      reject={Actions.advanceStepFailure} // the type of action that will reject the promise
    >
      {asyncFunc => (
        <SignupCard
          title="A few more questions"
          stepNumber={stepNumber}
          error={error}
        >
          <form
            css={getFormStyles()}
            onSubmit={handleSubmit(values =>
              // Uncaught error FIX
              asyncFunc(values).catch(e => {
                throw new SubmissionError({ _error: e.toString() })
              })
            )}
          >
            <IrisTextField
              css={textFieldStyles}
              formField={FormFieldNames.phoneNumber}
            />

            <IrisTextField
              css={textFieldStyles}
              formField={FormFieldNames.jobTitle}
            />

            <IrisSelect
              css={textFieldStyles}
              formField={FormFieldNames.industry}
              change={change}
              options={industryOptions}
              style={browserWidth > 920 ? { width: 210 } : {}}
            />

            <IrisSelect
              formField={FormFieldNames.country}
              css={textFieldStyles}
              change={change}
              options={countryOptions}
              style={browserWidth > 920 ? { width: 210 } : {}}
            />

            <IrisSelect
              css={textFieldStyles}
              formField={FormFieldNames.region}
              change={change}
              style={browserWidth > 920 ? { width: 210 } : {}}
              options={
                regions[countryIsoCode]
                  ? regions[countryIsoCode].map(r => ({
                      value: r,
                      text: r,
                    }))
                  : []
              }
            />

            <IrisTextField
              css={textFieldStyles}
              formField={FormFieldNames.city}
            />

            <IrisButton
              css={doubleColumn}
              variant="contained"
              color="primary"
              type="submit"
            >
              Get started
            </IrisButton>
          </form>
        </SignupCard>
      )}
    </MakeAsyncFunction>
  )
}

export const formName = 'step2'

const mapStateToProps = createStructuredSelector({
  countryIsoCode: state =>
    formValueSelector(formName)(state, FormFieldNames.country.name),
})

export default connect(
  mapStateToProps,
  null
)(
  reduxForm({
    // a unique name for the form
    form: formName,
    validate: validate(Object.values(FormFieldNames)),
  })(Step2)
)
