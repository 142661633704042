import React from 'react'
import {
  ConsentManager,
  openConsentManager,
  loadPreferences,
} from '@segment/consent-manager'

const CookieConsent = () => {
  const bannerContent = (
    <span>
      We use cookies (and other similar technologies) to collect data to improve
      your experience on our site. By using our website, you’re agreeing to the
      collection of data as described in our{' '}
      <a href="/cookies-policy/" target="_blank">
        Cookies Policy
      </a>
      .
    </span>
  )
  const bannerSubContent = 'You can change your preferences at any time.'
  const preferencesDialogTitle = 'Website Data Collection Preferences'
  const preferencesDialogContent =
    'We use data collected by cookies and JavaScript libraries to improve your browsing experience, analyze site traffic, deliver personalized advertisements, and increase the overall performance of our site.'
  const cancelDialogTitle = 'Are you sure you want to cancel?'
  const cancelDialogContent =
    'Your preferences have not been saved. By continuing to use our website, you՚re agreeing to our Website Data Collection Policy.'

  if (!window.IRISVR_ENV) {
    return null
  }

  return (
    <>
      <div style={{ position: 'fixed', right: 0, bottom: 0, left: 0 }}>
        <ConsentManager
          writeKey={window.IRISVR_ENV.segment_key}
          shouldRequireConsent={() => true}
          bannerContent={bannerContent}
          bannerSubContent={bannerSubContent}
          preferencesDialogTitle={preferencesDialogTitle}
          preferencesDialogContent={preferencesDialogContent}
          cancelDialogTitle={cancelDialogTitle}
          cancelDialogContent={cancelDialogContent}
          bannerBackgroundColor="#000000"
          css={{ width: 200 }}
          bannerActionsBlock={true}
          preferencesDialogTemplate={{
            categories: [
              {
                key: 'functional',
                example: '',
              },
              {
                key: 'marketing',
                name: 'Improve Your Experience',
                purpose: `Allows us to show you what is relevant to you. These cookies enable us to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we use to deliver information and experiences tailored to you. If you do not allow these cookies, some or all of these services may not be available for you.`,
                example: '',
              },
              {
                key: 'advertising',
                name: 'Customize Your Advertising',
                purpose: `Permits us to offer targeted advertising to you. These cookies collect data about you based on your activities and interests in order to show you relevant ads and to track effectiveness. By collecting this data, the ads you see will be more tailored to your interests. If you do not allow these cookies, you will experience less targeted advertising.`,
                example: '',
              },
              {
                key: 'essential',
                name: 'Strictly Necessary',
                purpose: `Required for our site to work and to provide services to you. These cookies allow us to record your preferences or login information, respond to your requests or fulfill items in your shopping cart.`,
                example: '',
              },
            ],
          }}
          customCategories={{
            'Improve Your Experience': {
              purpose: `Allows us to show you what is relevant to you. These cookies enable us to provide enhanced functionality and personalization. They may be set by us or by third party providers whose services we use to deliver information and experiences tailored to you. If you do not allow these cookies, some or all of these services may not be available for you.`,
              integrations: [
                'Google Universal Analytics',
                'HubSpot',
                'Visual Website Optimizer',
              ],
            },
            'Customize Your Advertising': {
              purpose: `Permits us to offer targeted advertising to you. These cookies collect data about you based on your activities and interests in order to show you relevant ads and to track effectiveness. By collecting this data, the ads you see will be more tailored to your interests. If you do not allow these cookies, you will experience less targeted advertising.`,
              integrations: [
                'Facebook Pixel',
                'LinkedIn Insight Tag',
                'Twitter Ads',
              ],
            },
            'Do Not Sell': {
              purpose: `We care about the privacy of your personal information. The information you provide here will only be used to process your opt-out of sale request and to improve our compliance with the California Consumer Privacy Act (CCPA). Please note that your request will apply to future sales of your personal information.`,
              integrations: [
                'Facebook Pixel',
                'LinkedIn Insight Tag',
                'Twitter Ads',
                'Google Universal Analytics',
                'HubSpot',
                'Visual Website Optimizer',
              ],
            },
          }}
        />
      </div>

      <footer
        style={{ background: '#dedede', padding: '28px 0', fontSize: 12 }}
      >
        <div style={{ margin: '0 auto', width: '88%', maxWidth: 1600 }}>
          <a
            href="https://www.autodesk.com/company/legal-notices-trademarks/privacy-statement"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#565656' }}
          >
            Privacy
          </a>{' '}
          |{' '}
          <button
            style={{
              display: 'inline',
              background: 'none',
              border: 0,
              padding: 0,
              margin: 0,
              appearance: 'none',
            }}
            type="button"
            onClick={openConsentManager}
          >
            Do not sell my personal information
          </button>{' '}
          |{' '}
          <a
            href="/cookies-policy"
            target="_blank"
            rel="noopener noreferrer"
            style={{ color: '#565656' }}
          >
            Cookies Policy
          </a>{' '}
          |{' '}
          <button
            style={{
              display: 'inline',
              background: 'none',
              border: 0,
              padding: 0,
              margin: 0,
              appearance: 'none',
            }}
            type="button"
            onClick={openConsentManager}
          >
            Cookie preferences
          </button>{' '}
          |{' '}
          <a
            href="https://www.autodesk.com/genuine/report-piracy"
            style={{ color: '#565656' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Report noncompliance
          </a>{' '}
          |{' '}
          <a
            href="https://irisvr.com/terms"
            style={{ color: '#565656' }}
            target="_blank"
            rel="noopener noreferrer"
          >
            Terms of use
          </a>{' '}
          | © 2022 Prospect by IrisVR, by Autodesk. All rights reserved.
        </div>
      </footer>
    </>
  )
}

export default CookieConsent
