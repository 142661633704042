import React from 'react'
import { openConsentManager } from '@segment/consent-manager'

const COOKIES = [
  {
    name: 'BigQuery',
    purpose:
      'To collect information regarding the activities that users engage in while visiting our web pages.',
    url: 'https://cloud.google.com/terms/cloud-privacy-notice',
  },
  {
    name: 'Facebook',
    purpose:
      'To obtain information regarding the activities that users engage in while visiting our web pages',
    url: 'https://www.facebook.com/about/privacy',
  },
  {
    name: 'Google Analytics',
    purpose:
      'To collect data about website usage which does not include personally identifiable information',
    url: 'https://irisvr.com/www.google.com/policies/privacy/',
  },
  {
    name: 'Hubspot',
    purpose:
      'To collect data on web page visits for email marketing and webinar campaigns',
    url: 'https://legal.hubspot.com/privacy-policy',
  },
  {
    name: 'Hull',
    purpose:
      'To provide website usage and user data to help us refine onboarding and build new features.',
    url: 'https://www.hull.io/pp',
  },
  {
    name: 'LinkedIn',
    purpose:
      'To obtain information on our website of the activities users engage in to facilitate retargeting',
    url: 'https://www.linkedin.com/legal/privacy-policy',
  },
  {
    name: 'Segment',
    purpose:
      'To provide website usage and user data to help us refine onboarding and build new features',
    url: 'https://segment.com/docs/legal/privacy/',
  },
  {
    name: 'Visual Website Optimizer',
    purpose:
      'To test different web configurations by measuring aggregate user engagement',
    url: 'https://vwo.com/privacy-policy/',
  },
]

const CookiesPolicy = () => {
  return (
    <article style={{ width: '88%', maxWidth: 960, margin: '40px auto' }}>
      <h1>Cookie Policy</h1>
      <p>Last updated: October 4, 2022</p>

      <p>
        IrisVR (“we”) use cookies, web beacons and other technologies
        (“Cookies”) on https://signup.irisvr.com to improve and customize our
        websites and products ("the Services") and your experience. Cookies help
        us understand how people use a website, remember a user’s login details,
        and store website preferences, and may help us advertise to those users.
        This page explains how we use Cookies to help us ensure that our
        Services function properly and analyze and improve the Services in
        accordance with our Privacy Statement, and your ability to control their
        use.{' '}
      </p>

      <hr style={{ opacity: '0.2', margin: '40px 0' }} />

      <p>We use the following types of Cookies: </p>
      <ol>
        <li>
          <p>
            <b>Strictly necessary Cookies.</b> These are Cookies that are
            required for the operation of our website. They include, for
            example, Cookies that enable you to log into our website or make use
            of online billing services. Big Query, Hull and Segment are strictly
            necessary Cookies.
          </p>
        </li>
        <li>
          <p>
            <b>Improve your experience.</b> These Cookies enable us to provide
            enhanced functionality and personalization. They may be set by us or
            by third party providers whose services we use to deliver
            information and experiences tailored to you. Examples of these
            enhanced functionality and personalization include providing more
            relevant experiences on websites, understanding your interests so we
            can provide you with more relevant content online, measuring your
            use of our applications to improve them and your experience.
          </p>
        </li>
        <li>
          <p>
            <b>Customize your advertising.</b> These Cookies collect data about
            you based on your activities and interests in order to show you
            relevant ads and to track effectiveness. By collecting this data,
            the ads you see will be more tailored to your interests. These
            Cookies may also send information to third parties that help us
            create and deliver advertising personalized to you and your
            interests.
          </p>
          <p>
            You can also opt-out of receiving personalized advertisements from
            third party advertisers and ad networks that are members of the
            Digital Advertising Alliance's Self-Regulatory Principles for Online
            Behavioral Advertising by visiting{' '}
            <a
              href="http://optout.aboutads.info/"
              target="_blank"
              rel="noopener noreferrer"
            >
              http://optout.aboutads.info/
            </a>
            .
          </p>
        </li>
      </ol>

      <hr style={{ opacity: '0.2', margin: '40px 0' }} />

      <h2>First-party (IrisVR) Cookies</h2>
      <p>
        Our websites will set local storage data that is essential for the
        website to operate correctly. These are as follows:
      </p>

      <ul>
        <li>
          <b>Token</b> – this cookie is a URL-safe JSON web token (JWT) designed
          to enable the single sign-on (SSO) web browsing experience. it also
          verifies the user’s access to any personal data they may request from
          the IrisVR servers.
        </li>
        <li>
          <b>User ID</b> – this cookie is a numeric value that identifies unique
          visitors and provides coherence and consistency to a site visit.{' '}
        </li>
        <li>
          <b>Username</b> – this cookie is a text value that identifies unique
          visitors by user email and provides data for email notifications.
        </li>
      </ul>

      <hr style={{ opacity: '0.2', margin: '40px 0' }} />

      <h2>Third-party Cookies</h2>
      <p>
        We do not control the operation of third-party Cookies on our website.
        These include:{' '}
      </p>

      <table>
        <thead>
          <tr style={{ fontWeight: 'bold' }}>
            <td style={{ padding: 10 }}>Set by or on behalf of</td>
            <td style={{ padding: 10 }}>Purpose</td>
            <td style={{ padding: 10 }}>Privacy Notice</td>
          </tr>
        </thead>
        <tbody>
          {COOKIES.map(cookie => {
            return (
              <tr key={cookie.name}>
                <td style={{ padding: '5px 10px' }}>{cookie.name}</td>
                <td style={{ padding: '5px 10px' }}>{cookie.purpose}</td>
                <td style={{ padding: '5px 10px' }}>
                  <a href={cookie.url}>{cookie.url}</a>
                </td>
              </tr>
            )
          })}
        </tbody>
      </table>

      <hr style={{ opacity: '0.2', margin: '40px 0' }} />

      <h2>How to manage cookies</h2>
      <p>
        With the exception of Cookies that are strictly necessary for our
        applications, we provide you with choices about our use of Cookies on
        our applications, including seeking your consent as may be required by
        law. You can manage your preferences for Cookies{' '}
        <button
          onClick={openConsentManager}
          style={{
            background: 'none',
            appearance: 'none',
            border: 'none',
            margin: 0,
            padding: 0,
            textDecoration: 'underline',
          }}
        >
          here
        </button>
        . Please keep in mind that you may also need to clear your Cookies on
        each browser on each device, in order to reset these settings.
      </p>
      <p>
        You can also modify your browser settings to control whether your
        computer or other device accepts or declines Cookies. These settings are
        generally available within your browser’s "Help", "Tools", or "Edit"
        menus. Keep in mind that your browser settings may prevent you from
        accessing all functionality on our website.{' '}
      </p>
    </article>
  )
}

export default CookiesPolicy
