// this comment tells babel to convert jsx to
// calls to a function called jsx
// instead of React.createElement
/** @jsx jsx */
import { jsx, css } from '@emotion/core'
// eslint-disable-next-line no-unused-vars
import React from 'react'
import logo from './logos/irisvr-logo-black.svg'

const marginStyles = css`
  @media (min-width: 921px) {
    margin-bottom: 3rem;
    margin-left: 3rem;
    margin-right: 3rem;
  }
`

const formStyles = [
  marginStyles,
  css({
    '@media (min-width: 921px)': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      form: {
        maxWidth: '40.6rem',
        width: '100%',
      },
    },
    '@media (max-width: 920px)': {
      width: '100%',
      display: 'flex',
      justifyContent: 'center',
      form: {
        width: '90%',
      },
    },
    '@media (max-width: 400px)': {},
  }),
]

export default function({
  error,
  invertedColors,
  title,
  stepNumber,
  children,
}) {
  const textColor = invertedColors ? '#FFFFFF' : '#333333'
  const cardStyles = css({
    borderWidth: '0.5rem',
    borderRadius: '0.4rem',
    backgroundColor: invertedColors ? '#534DC9' : '#FFFFFF',
    color: textColor,
    maxWidth: '54.4rem',
    minHeight: stepNumber === 3 ? '40rem' : '48rem',
    '@media (min-width: 921px)': {},
    '@media (max-width: 920px)': {
      minHeight: stepNumber !== 1 ? '250px' : 'auto',
    },
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    boxShadow: '0px 0px 16px rgba(0, 0, 0, 0.05)',
    paddingTop: 30,
    paddingBottom: 30,
  })

  const pillarStyles = css`
    display: flex;
    flex-direction: column;
    align-items: center;
  `

  const stepStyles = [
    css`
      maxwidth: 40.6rem;
      height: 3.5rem;
      left: 23.3rem;
      top: 24.5rem;

      font-family: irisvr;
      font-style: normal;
      font-weight: normal;
      font-size: 1.4rem;
      line-height: 1.7rem;
      text-align: center;
      text-transform: uppercase;
    `,
    css({
      color: invertedColors ? '#FFFFFF' : '#acb9ef',
    }),
  ]

  const titleStyles = [
    css`
      width: 100%;

      font-family: irisvr;
      font-style: normal;
      font-weight: normal;
      text-align: center;

      margin-bottom: 1rem;
    `,
    css({
      '@media (min-width: 921px)': {
        fontSize: '3.4rem',
      },
      '@media (max-width: 920px)': {
        fontSize: '2.4rem',
      },
    }),
    css({
      color: textColor,
    }),
  ]

  const errorStyles = css`
    color: red;
    text-align: center;
    font-size: 1.2rem;
    margin-bottom: 3rem;
  `

  const logoStyles = [
    css`
      width: 13.6rem;
      margin: 0 0 3rem 0;
    `,
    css({
      fill: invertedColors ? 'white !important' : 'black',
    }),
  ]

  return (
    <div css={cardStyles}>
      <div css={pillarStyles}>
        <img css={logoStyles} src={logo} alt="IrisVR logo" />

        <div css={titleStyles}>{title}</div>
        <div css={stepStyles}>Step {stepNumber} of 3</div>

        {error && <div css={errorStyles}>{error}</div>}

        <div css={formStyles}>{children}</div>
      </div>
    </div>
  )
}
