import { applyMiddleware, combineReducers, compose, createStore } from 'redux'
import { connectRouter, routerMiddleware } from 'connected-react-router'
import createSagaMiddleware from 'redux-saga'
import signupReducer, { reducerKey as signupReducerKey } from './signup/duck'
import { sagas as signupSagas } from './signup/sagas'
import { reducer as formReducer } from 'redux-form'
import { all } from 'redux-saga/effects'
import { getHistory } from './history'
import createReduxPromiseListener from 'redux-promise-listener'
import logger from 'redux-logger'

const sagaMiddleware = createSagaMiddleware()

const createRootReducer = history =>
  combineReducers({
    [signupReducerKey]: signupReducer,
    form: formReducer,
    router: connectRouter(history),
  })

// Create a Redux store holding the state of your app.
// Its API is { subscribe, dispatch, getState }.
function* allSagas() {
  yield all([...signupSagas])
}

const reduxPromiseListener = createReduxPromiseListener()

let store
export const getStore = () => {
  if (!store) {
    const preloadedState = {}

    let history = getHistory()

    store = createStore(
      createRootReducer(history),
      preloadedState,
      compose(
        applyMiddleware(
          sagaMiddleware,
          routerMiddleware(history),
          reduxPromiseListener.middleware,
          logger
        )
      )
    )
    sagaMiddleware.run(allSagas)
  }
  return store
}

export const promiseListener = reduxPromiseListener // <---- ⚠️ IMPORTANT ⚠️
