// From https://bitbucket.org/atlassian/atlaskit-mk-2/raw/4ad0e56649c3e6c973e226b7efaeb28cb240ccb0/packages/core/select/src/data/countries.js
import countryData from './countries.json'
export const countries = countryData

// returns a map of name to country
export const countriesByName = countries.reduce((obj, item) => {
  obj[item.name] = item
  return obj
}, {})

// returns a map of iso code 2 to country
export const countriesByAlpha2 = countries.reduce((obj, item) => {
  obj[item.alpha2] = item
  return obj
}, {})

export const nameToIsoCode = name =>
  countriesByName[name] ? countriesByName[name].alpha2 : undefined

export const isoCodeToName = alpha2 =>
  countriesByAlpha2[alpha2] ? countriesByAlpha2[alpha2].name : undefined
