// this comment tells babel to convert jsx to
// calls to a function called jsx
// instead of React.createElement
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx, css } from '@emotion/core'
// eslint-disable-next-line no-unused-vars
import React from 'react'
import SignupCard from '../SignupCard'

const resendEmailStyles = css`
  margin-top: 3rem;
  font-size: 1.4rem;
  text-decoration: underline;
  text-align: center;
  letter-spacing: 0.05em;
  cursor: pointer;
  color: #534dc9;
`

const columnar = css`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Step3 = ({ resendAccountConfirmationEmail }) => {
  const invertedColors = false
  const checkYourInboxStyles = [
    css`
      width: 34rem;
      margin-top: 3rem;
      font-size: 2.4rem;
      text-align: center;
      letter-spacing: 0.05em;
      line-height: 3rem;
    `,
    css({
      fontWeight: invertedColors ? 400 : 300,
    }),
  ]

  return (
    <SignupCard
      invertedColors={invertedColors}
      title="Confirm your account"
      stepNumber={3}
    >
      <div css={columnar}>
        <div css={checkYourInboxStyles}>
          Please check your inbox for a confirmation email.
        </div>

        <div
          onClick={() => resendAccountConfirmationEmail()}
          css={resendEmailStyles}
        >
          RESEND EMAIL
        </div>
      </div>
    </SignupCard>
  )
}

export default Step3
