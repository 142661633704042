// this comment tells babel to convert jsx to
// calls to a function called jsx
// instead of React.createElement
/** @jsx jsx */
// eslint-disable-next-line no-unused-vars
import { jsx, css } from '@emotion/core'
// eslint-disable-next-line no-unused-vars
import React, { useState } from 'react'
import Recaptcha from '../Recaptcha'
import SignupCard from '../SignupCard'
import { reduxForm, SubmissionError } from 'redux-form'
import {
  IrisTextField,
  IrisCheckbox,
  IrisButton,
  validate,
  Validators,
} from '../../common'
import { connect } from 'react-redux'
import MakeAsyncFunction from 'react-redux-promise-listener'
import { promiseListener } from '../../configureStore'
import { ActionCreators, Actions } from '../duck'
import { bindActionCreators } from 'redux'

const stepNumber = 1

const fullWidth = css`
  @media (min-width: 921px) {
    width: 100%;
  }
  @media (max-width: 920px) {
    width: 100%;
    margin: auto;
    margin-bottom: 10px;
  }
`
const textFieldStyles = [fullWidth]

const formStyles = css`
  @media (min-width: 921px) {
    display: grid;
    grid-template-columns: 100%;
    grid-column-gap: 10px;
    grid-gap: 1.5rem;
  }
  @media (max-width: 920px) {
  }
`

const checkboxStyles = css`
  display: flex;
  justify-content: center;
`

// redux-form gives us these props: pristine, submitting, handleSubmit
const Step1 = ({ actions: { setRecaptcha }, error, handleSubmit }) => {
  return (
    <MakeAsyncFunction
      listener={promiseListener}
      start={Actions.advanceStepRequest} // the type of action to dispatch when this function is called
      resolve={Actions.advanceStepSuccess} // the type of action that will resolve the promise
      reject={Actions.advanceStepFailure} // the type of action that will reject the promise
    >
      {asyncFunc => (
        <SignupCard
          title="Start your free trial today"
          stepNumber={stepNumber}
          error={error}
        >
          <form
            css={formStyles}
            onSubmit={handleSubmit(values =>
              // Uncaught error FIX
              asyncFunc(values).catch(e => {
                throw new SubmissionError({ _error: e.toString() })
              })
            )}
          >
            <IrisTextField
              css={textFieldStyles}
              formField={FormFieldNames.fullName}
            />

            <IrisTextField
              css={textFieldStyles}
              formField={FormFieldNames.username}
            />

            <IrisTextField
              css={textFieldStyles}
              formField={FormFieldNames.password}
              type="password"
            />

            <IrisTextField
              css={textFieldStyles}
              formField={FormFieldNames.confirmPassword}
              type="password"
            />

            <IrisTextField
              css={textFieldStyles}
              formField={FormFieldNames.company}
            />

            <div style={{ display: 'flex' }}>
              <IrisCheckbox
                css={checkboxStyles}
                formField={FormFieldNames.terms}
              />
            </div>

            <div style={{ display: 'flex' }}>
              <IrisCheckbox
                css={checkboxStyles}
                formField={FormFieldNames.emailSubscription}
              />
            </div>

            <Recaptcha
              css={css`
                margin-top: 10px;
                margin-bottom: 10px;
                display: flex;
                justify-content: center;
                min-height: 78px;
              `}
              size="compact"
              onVerify={() => setRecaptcha(true)}
            />

            <IrisButton
              css={css`
                 {
                  width: 100%;
                }
              `}
              variant="contained"
              color="primary"
              type="submit"
            >
              Continue
            </IrisButton>
          </form>
        </SignupCard>
      )}
    </MakeAsyncFunction>
  )
}

export const formName = 'step1'

// const isDev = process.env.NODE_ENV === 'development'
const isDev = false

const TermsLabel = () => {
  return (
    <span>
      I agree to the
      <a
        href="https://irisvr.com/terms/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Terms&nbsp;of&nbsp;Use
      </a>{' '}
      and acknowledge the{' '}
      <a
        href="https://irisvr.com/privacy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy&nbsp;Statement
      </a>
      .
    </span>
  )
}

const CommsLabel = () => {
  return (
    <span>
      Check this box to receive electronic marketing communications on news,
      trends, events, special offers and research surveys. You can unsubscribe
      at any time. To learn more, see our{' '}
      <a
        href="https://irisvr.com/privacy/"
        target="_blank"
        rel="noopener noreferrer"
      >
        Privacy&nbsp;Statement
      </a>
      .
    </span>
  )
}

export const FormFieldNames = {
  fullName: {
    name: 'full_name',
    placeholder: 'Full Name',
    defaultValue: isDev ? 'Kevin Chen' : '',
    validationRules: [Validators.required, Validators.minLength(2)],
  },
  username: {
    name: 'username',
    placeholder: 'Work Email',
    defaultValue: isDev ? 'kevin.chen@irisvr.com' : '',
    validationRules: [
      Validators.required,
      Validators.regex(
        /^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$/
      ),
    ],
  },
  password: {
    name: 'password',
    placeholder: 'Password',
    defaultValue: isDev ? 'password' : '',
    validationRules: [Validators.required, Validators.minLength(8)],
  },
  confirmPassword: {
    name: 'confirmPassword',
    placeholder: 'Confirm Password',
    defaultValue: isDev ? 'password' : '',
    validationRules: [Validators.required, Validators.minLength(8)],
  },
  company: {
    name: 'company',
    placeholder: 'Company Name',
    defaultValue: isDev ? 'IrisVR' : '',
    validationRules: [Validators.required, Validators.minLength(2)],
  },
  emailSubscription: {
    name: 'email_subscription',
    defaultValue: false,
    placeholder: <CommsLabel />,
  },
  terms: {
    name: 'terms',
    defaultValue: false,
    placeholder: <TermsLabel />,
    validationRules: [Validators.requiredWithMsg('This field is required')],
  },
}

const mapStateToProps = state => ({
  initialValues: Object.values(FormFieldNames).reduce((obj, item) => {
    obj[item.name] = item.defaultValue
    return obj
  }, {}),
  pristine: true,
})

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(
    {
      setRecaptcha: ActionCreators.setRecaptcha,
    },
    dispatch
  ),
})

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(
  reduxForm({
    // a unique name for the form
    form: formName,
    touchOnBlur: true,
    asyncBlurFields: [
      FormFieldNames.password.name,
      FormFieldNames.confirmPassword.name,
    ],
    validate: (values, props) => {
      const errors = validate(Object.values(FormFieldNames))(values)

      const currentPassword = values[FormFieldNames.password.name]
      const currentConfirmPassword = values[FormFieldNames.confirmPassword.name]

      const bothPasswordFieldsPopulated =
        currentPassword !== '' && currentConfirmPassword !== ''

      // If the two password fields do not match
      if (
        bothPasswordFieldsPopulated &&
        currentPassword !== currentConfirmPassword
      ) {
        // errors[FormFieldNames.password.name] = 'Passwords must match'
        errors[FormFieldNames.confirmPassword.name] = 'Passwords must match'
      }

      // if (
      //   errors[FormFieldNames.password.name] !==
      //   errors[FormFieldNames.confirmPassword.name]
      // ) {
      //   errors[FormFieldNames.password.name] = ''
      //   errors[FormFieldNames.confirmPassword.name] = ''
      // }

      return errors
    },
  })(Step1)
)
